import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

/**
 * Fetches all partner admins (bookers) of a partnership
 *
 * @param {int} id of partnership
 * @returns {promise}
 */
export const getPartnerAdminsWithPartnership = (partnershipId, query) => {
  const url = `${getApiPlatformBaseUrl()}/club-users?partnerships.id=${partnershipId}${query}`

  return request(url, { method: 'GET', accept: 'application/ld+json' })
}

/**
 * Assign admin to partnership
 *
 * @param {int} id of partnership
 * @param {string} email of admin
 * @returns {promise}
 */
export const addAdminToPartnership = (partnershipId, email) => {
  const url = `${getApiPlatformBaseUrl()}/crm/partnerships/${partnershipId}/booker/add`

  return request(url, { method: 'PUT', body: { email: email } })
}

/**
 * Assign admin to partnership
 *
 * @param {int} partnershipId of partnership
 * @param {string} email of admin
 * @returns {promise}
 */
export const addPartnerBookerToPartnership = (partnershipId, email) => {
  const url = `${getApiPlatformBaseUrl()}/crm/partnerships/${partnershipId}/partner-booker`

  return request(url, { method: 'PUT', body: { email: email } })
}

/**
 * Remove admins from partnership
 *
 * @param {int} id of partnership
 * @param {array} array of admin id's
 * @returns {promise}
 */
export const removeAdminsFromPartnership = (partnershipId, adminArray) => {
  const url = `${getApiPlatformBaseUrl()}/crm/partnerships/${partnershipId}/booker/remove`

  return request(url, { method: 'PUT', body: { bookers: adminArray } })
}
