import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

/**
 * Fetches the users partnerships
 *
 * @returns {Promise}
 */
export const getPartnershipList = (query) => {
  const url = `${getApiPlatformBaseUrl()}/crm/partnerships?${query}`

  return request(url, { method: 'GET', accept: 'application/ld+json' })
}

/**
 * Fetches a single partnership
 *
 * @returns {Promise}
 */
export const getPartnership = (id) => {
  const url = `${getApiPlatformBaseUrl()}/partnerships/${id}`

  return request(url, { method: 'GET' })
}
