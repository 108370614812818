import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

/**
 * Get golf clubs belonging to CA
 *
 * @returns {promise}
 */
export const getCAGolfClubs = (clubAdminId) => {
  const url = `${getApiPlatformBaseUrl()}/clubs?admins.id=${clubAdminId}`
  return request(url, { method: 'GET' })
}
