import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'
import { GetNextAvailableTeeTimeQuery, NextAvailableTeeTime } from './types'

export const getNextAvailableTeeTime = (
  query: GetNextAvailableTeeTimeQuery = {
    'course.uuid': null,
    'from[before]': null,
    'from[after]': null,
    page: 1,
    limit: 50,
  }
): Promise<NextAvailableTeeTime> => {
  const URL = queryString.stringifyUrl({
    url: `${getApiPlatformBaseUrl()}/tee-times/get-next-available`,
    query,
  })

  return request(URL)
}
