import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

/**
 * Request to get courses belonging to a club
 *
 * @param {number} id
 * @returns {promise}
 */
export const getGolfCoursesFromGolfClub = (id) => {
  const url = `${getApiPlatformBaseUrl()}/clubs/${id}/courses`

  return request(url, { method: 'GET' })
}
