import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'
import { ToastProvider } from 'react-toast-notifications'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './store/configureStore'
import CustomToast from './components/CustomToast'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import packageJson from '../package.json'

// Styles that get put in head
import './styles/global.scss'
import '@sweetspot/sweetspot-js/ui-kit/scss/global.scss'

import App from './app'
import * as serviceWorker from './serviceWorker'
import ReactQueryWrapper from './react-query-wrapper'
import { ErrorBoundaryFallback } from '@sweetspot/shared/util/error-handling'

import { initPartnerPortalI18next } from '@sweetspot/shared/translations'
import { APP_NAMES } from '@sweetspot/shared/util/constants'
import { initAmplitude } from '@sweetspot/shared/util/amplitude'

initPartnerPortalI18next()
initAmplitude({
  autocapture: true,
})
const appVersion = packageJson?.version || '0.0.0'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://12e4d03e5c6b4c75953acb43a8e63f14@o250597.ingest.sentry.io/5642884',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    environment: process.env.REACT_APP_API_ENV,
    release: `partner-portal@${appVersion}`,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,

    // Replay settings
    blockAllMedia: false,
    maskAllText: false,
  })
}

const { store, persistor } = configureStore()
const rootElement = document.getElementById('root')

const root = createRoot(rootElement)
const renderApp = () =>
  root.render(
    <Sentry.ErrorBoundary
      fallback={(props) => (
        <ErrorBoundaryFallback appOrigin={APP_NAMES.PARTNER_PORTAL} {...props} />
      )}
      beforeCapture={(scope) => {
        scope.setTag('location', 'partner-portal')
      }}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ReactQueryWrapper>
            <ToastProvider
              placement="bottom-center"
              autoDismissTimeout={4000}
              autoDismiss={true}
              components={{ Toast: CustomToast }}
            >
              <App appVersion={appVersion} />
            </ToastProvider>
          </ReactQueryWrapper>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  )

// Allow hot reloading
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./app', renderApp)
}

renderApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
